import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['burgerBtn', 'nav'];

  connect() {
    this.handleClickOutside = this.handleClickOutside.bind(this);
    document.addEventListener('click', this.handleClickOutside);
  }

  toggle() {
    this.burgerBtnTarget.classList.toggle('is-open');
    this.navTarget.classList.toggle('is-open');
  }

  handleClickOutside(event) {
    // Check if the click happened outside the nav element
    if (!this.navTarget.contains(event.target) && !this.burgerBtnTarget.contains(event.target)) {
      this.closeNav();
    }
  }

  closeNav() {
    this.navTarget.classList.remove('is-open');
    this.burgerBtnTarget.classList.remove('is-open');
  }
}
